.featured-image img {
    width: 100%;
}

.services {
    margin-bottom: 20px;
}

.services .middle-content {
    margin-bottom: 0;
}

.services .icon-heading i {
    margin-right: 30px;
}

.services .icon-heading {
    flex-direction: row-reverse;
    justify-content: flex-end;
}

.services .col-md-4 p {
    font-size: 13px;
    color: #6b6b6b;
}

.service-overview {
    font-size: 13px;
    color: #6b6b6b;
    text-align: center;
    margin: 60px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
