@font-face {
    font-family: "Montserrat Bold";
    src: url("./fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat Heavy";
    src: url("./fonts/Montserrat-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat Medium";
    src: url("./fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat Semi Bold";
    src: url("./fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat Light";
    src: url("./fonts/Montserrat-Light.ttf") format("truetype");
}

.grecaptcha-badge {
    visibility: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Montserrat Bold";
}

p,
span {
    font-family: "Montserrat";
    margin: 0;
}

body {
    margin: 0;
    padding: 0;
}

section {
    margin: 0;
    padding: 0;
}

.nav-container {
    max-width: 1100px;
    width: 100%;
    padding: 0;
}

.container {
    max-width: 1100px;
    width: 100%;
    padding: 0;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .container {
        padding: 0 16px;
        max-width: 90%;
    }
}

nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.5s;
}

nav img {
    height: auto;
    width: 210px;
}

nav p,
nav span {
    font-family: "Montserrat";
    margin: 0;
    font-size: 13px;
}

nav .logo {
    margin: 20px 0 20px 0;
}

nav .nav-container {
    max-width: 1100px;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    transition: 0.5s;
}

@media (max-width: 768px) {
    nav .nav-container {
        display: block;
    }
}

nav .logo,
nav .call,
nav .time {
    display: flex;
    justify-content: center;
}

.nav-contact-div {
    display: flex;
}

@media (max-width: 768px) {
    .nav-contact-div {
        align-items: center;
        flex-direction: column;
    }
}

nav .call,
nav .time {
    margin: 15px 30px;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    nav .call {
        margin: 0;
    }

    nav .call,
    nav .time {
        flex-direction: column;
    }
}

nav .call i,
nav .time i {
    border: #e1e1e1 solid 1px;
    border-radius: 50%;
    padding: 17px;
    margin-right: 10px;
    transition: 0.5s;
}

nav .call i:hover,
nav .time i:hover {
    color: #fd3f00;
    border-color: #fd3f00;
}

@media (max-width: 768px) {
    nav .call i,
    nav .time i {
        margin-right: 0;
        margin-bottom: 5px;
    }
}

nav .call p,
nav .time p {
    margin-bottom: 5px;
    color: #848484;
    font-weight: bold;
}

nav .desktop-view {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #242424; */
    /* color: #8c8c8c; */
    background-color: #ffffff;
    border-top: 1px solid #e1e1e1;
    color: #242424;
    min-height: 70px;
    width: 100%;
}

.navigation-scrolled {
    background-color: #ffffff;
    color: #000000;
}

.navigation ul {
    font-family: "Montserrat Medium";
    font-size: 14px;
    display: flex;
    list-style: none;
    padding-left: 0;
}

.navigation ul li {
    height: 100%;
    margin-right: 40px;
}

@media (max-width: 768px) {
    .navigation ul {
        width: 100%;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .navigation ul li {
        margin-right: 0;
    }
}

.navigation ul li:last-child {
    margin-right: 0;
}

.navigation ul li a {
    padding: 22px 0;
    height: 100%;
    text-decoration: none;
    color: inherit;
    transition: 0.5s;
}

@media (max-width: 768px) {
    .navigation ul li a {
        height: 20px;
        padding: 7px;
    }
}

.navigation ul li a:hover {
    color: #fd3f00;
    border-bottom: 3px solid #fd3f00;
}

.navigation .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 768px) {
    .navigation .container {
        flex-direction: column;
        justify-content: center;
    }
}

.nav-social-container {
    margin: 20px 0;
}

.nav-social-container a {
    text-decoration: none;
    font-size: 19px;
    color: inherit;
    margin-right: 40px;
    transition: 0.5s;
}

.nav-social-container a i {
    color: inherit;
    transition: 0.5s;
}

.nav-social-container a:hover,
.nav-social-container a:hover i {
    color: #fd3f00;
}

@media (max-width: 768px) {
    .nav-social-container a {
        margin-right: 30px;
    }
}

.nav-social-container a:last-child {
    margin-right: 0;
}

.nav-container_disabled {
    display: none !important;
    transition: 0.5s;
}

@media (max-width: 768px) {
    .nav-social-container-disabled {
        min-height: 92px;
        display: none !important;
    }
}

.fixed-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    transition: 0.5s;
}

.link-border-patch {
    padding: 22px 0 !important;
    height: 100% !important;
}

footer {
    margin: 0;
    background-position: center;
    background-size: cover;
    background-blend-mode: overlay;
    background-color: #242424;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
}

footer .container {
    margin: 30px 0;
    border: #8c8c8c solid 1px;
    border-left: none;
    border-right: none;
}

footer .details h4 {
    margin-bottom: 15px;
}

footer .details p {
    margin-top: 25px;
}

footer p {
    color: #8c8c8c;
    font-size: 13px;
}

footer .copy-right {
    margin-bottom: 20px;
}

footer .copy-right span {
    color: #fd3f00;
}

footer ul {
    list-style: none;
    padding: 0;
    font-size: 13px;
    color: #8c8c8c;
}

footer ul li {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
}

footer ul li i {
    margin-right: 0.5rem;
    font-size: 12px;
}

footer ul li p {
    margin: 0 !important;
    margin-bottom: 2px;
}

footer .business-hours h5 {
    margin-bottom: 5px;
}

footer .business-hours p {
    margin-top: 5px;
}

.active {
    color: #fd3f00 !important;
    border-bottom: 3px solid #fd3f00;
}
