.about-overview h2 {
    margin-bottom: 0px;
}

.about-overview h3 span {
    color: #fd3f00;
    font-family: "Montserrat Bold";
}

.owner {
    margin-top: 40px;
    border: 1px solid #e1e1e1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.owner img {
    max-height: 250px;
    width: auto;
}

@media (max-width: 768px) {
    .owner {
        margin-top: 0;
    }

    .owner img {
        max-height: none;
        width: 85%;
    }
}
