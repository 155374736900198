.review {
    padding: 20px;
}

.review img {
    width: 50px;
}

.user-profile {
    display: flex;
}

.profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
}

.profile h5 {
    margin: 0;
    margin-bottom: 5px;
}

.profile span {
    color: #6b6b6b;
    font-size: 15px;
}

.date {
    margin-top: 5px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.date img {
    width: 90px;
}

.date span {
    margin-left: 5px;
    font-size: 14px;
    color: #6b6b6b;
}
