button {
    width: 176px;
    background-color: #fd3f00;
    color: #ffffff;
    padding: 12px 35px;
    border: 2px solid transparent;
    border-radius: 2px;
    font-family: "Montserrat Bold";
    transition: 0.4s;
}

button:hover {
    border-color: #fd3f00;
    background-color: transparent;
    color: #fd3f00;
    white-space: nowrap;
}

.welcome-section {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center center;
    background-size: cover;
    min-height: 205px;
    color: #ffffff;
}

.welcome-section .container img {
    margin-top: 20px;
    width: 150px;
}

.welcome-section a,
.costomers-served a,
.service-overview a {
    text-align: center;
    text-decoration: none;
    color: inherit;
    width: 110px;
    background-color: #fd3f00;
    color: #ffffff;
    padding: 12px 35px;
    margin-top: 30px;
    border: 2px solid transparent;
    border-radius: 2px;
    font-family: "Montserrat Bold";
    font-size: 12px;
    transition: 0.4s;
}

.welcome-section a:hover,
.costomers-served a:hover {
    border-color: #fd3f00;
    background-color: transparent;
    color: #fd3f00;
    white-space: nowrap;
}

.welcome-section h1,
.welcome-section p {
    margin: 0;
}

.welcome-section p {
    font-size: 14px;
}

.welcome-section .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

@media (max-width: 768px) {
    .welcome-section h1 {
        margin-bottom: 20px;
    }
}

.middle-content {
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dash {
    display: block;
    width: 70px;
    border-bottom: 3px solid #fd3f00;
    margin-bottom: 30px;
}

.coming-soon {
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.coming-soon h3 span {
    font-family: "Montserrat";
}
