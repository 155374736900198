.welcome-section h1 {
    font-family: "Montserrat Semi Bold";
    margin-bottom: 30px;
}

.welcome-section h1 span {
    color: #fd3f00;
    font-family: "Montserrat Heavy";
}

.cursor {
    background-color: #fd3f00;
    animation: blink 1s steps(2) infinite;
    margin-left: 3px;
}

.cursor.visible {
    visibility: visible;
}

.cursor:not(.visible) {
    visibility: hidden;
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}

.middle-content h2 {
    text-align: center;
}

.middle-content p {
    font-size: 13px;
    text-align: center;
    color: #6b6b6b;
}

.middle-content .row {
    margin-top: 50px;
}

.middle-content .row p {
    text-align: start;
}

.middle-content .col-md-4 {
    padding-right: 50px;
}

.middle-content .col-md-4 img {
    width: 100%;
}

.middle-content .col-md-4:last-child {
    padding-right: 0;
}

.icon-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
}

@media (max-width: 768px) {
    .icon-heading {
        flex-direction: column-reverse;
        justify-content: center;
        margin-bottom: 5px;
    }

    .middle-content .col-md-4 {
        padding-right: 0;
    }

    .middle-content .col-md-4 img {
        display: none;
    }
}

.icon-heading h5 {
    font-family: "Montserrat Semi Bold";
    margin-right: 20px;
}

.icon-heading i {
    color: #fd3f00;
    font-size: 25px;
}

.costomers-served {
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    min-height: 200px;
    color: #ffffff;
}

.costomers-served .col-md-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0;
}

.costomers-served .col-md-3:last-child {
    justify-content: center;
}

@media (max-width: 768px) {
    .costomers-served {
        min-height: 450px;
    }
}

.gallery {
    position: relative;
    background-position: center center;
    overflow: hidden;
}

.gallery::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: rgba(255, 255, 255, 0.3); */
    backdrop-filter: blur(10px);
    z-index: 1;
}

.gallery .container {
    position: relative;
    z-index: 2;
}

@media (max-width: 768px) {
    .gallery .container {
        width: 90%;
    }
}
