.contact-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 70px 0;
}

.details {
    margin-bottom: 40px;
}

.details h4 {
    margin-bottom: 25px;
}

.details span {
    display: block;
    width: 50px;
    border-bottom: 3px solid #fd3f00;
}

form label {
    font-family: "Montserrat";
    font-size: 13px;
    margin-bottom: 5px;
}

form input,
form textarea {
    border: 1px solid #e1e1e1;
    outline-color: #e1e1e1;
    padding: 5px 10px;
}

form input {
    height: 30px;
}

.google-policy {
    margin-top: 5px;
}

form small {
    font-size: 10px;
}

form div {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

form .checkBoxContainer {
    border: 1px solid #e1e1e1;
    padding: 15px;
}

form .checkBoxContainer div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
}

form .checkBoxContainer div label {
    font-size: 12px;
}

form .checkBoxContainer input {
    height: 13px;
    margin-right: 10px;
    color: #fd3f00;
}

.intouch-icons {
    justify-content: space-between;
}

.intouch-icons .col-md-5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    border: 1px solid #e1e1e1;
    margin: 20px 10px;
    padding: 40px 0px;
    height: 212px;
    transition: 0.5s;
}

@media (max-width: 768px) {
    .intouch-icons .col-md-5 {
        margin: 20px 0;
    }
}

.intouch-icons .col-md-5:hover {
    color: #ffffff !important;
    background-color: #fd3f00;
}

.intouch-icons .col-md-5 i {
    color: #fd3f00;
    font-size: 25px;
}

.intouch-icons .col-md-5:hover i {
    color: #ffffff;
}

.intouch-icons .col-md-5 p {
    font-size: 10px;
    font-family: "Montserrat Bold";
}

.intouch-icons .col-md-5 span {
    font-family: "Montserrat";
    font-size: 10px;
}
